@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #141414;
  color: white;
}

.ant-table-tbody {
  background-color: #121212;
  color: white;
}

.ant-card-body {
  background-color: #121212;
  color: white;
}

.ant-card-head-title {
  color: #ffffff;
}

.ant-input {
  background-color: #121212;
  border-color: #202020;
  color: #ffffff;
}

.ant-card {
  border-color: #333333;
  border-radius: 9px;
}

.ant-input:hover {
  background-color: #121212;
}

.ant-input:focus {
  background-color: #121212;
}

.logo > a {
  text-decoration: none;
  color: #ffffff;
}

.ant-table-cell {
  background-color: #121212;
}

.ant-table-cell:hover {
  background-color: #121212;
}

.ant-pagination-item {
  color: #ffffff;
}

.add-button {
  margin-right: 10px;
}

.users-info {
  margin-bottom: 50px;
}

.ant-spin-nested-loading .ant-spin-container::after {
  background-color: #121212;
}

.nickname {
  margin-top: -15px;
  margin-bottom: 15px
}

.shop-nav {
  margin-bottom: 15px;
}

.ant-pagination .ant-pagination-item a {
  color: #ffffff;
}

.ant-pagination .ant-pagination-item a {
  color: #ffffff;
}

.week-stats {
  margin-bottom: 50px;
}

.primary-span {
  color: #FF69B4
}

